import NavHeader from "../components/NavHeader";
import StartScreen from "../components/StartScreen";
import { ScreenType, useHomeContext } from "../contexts/HomeContextProvider";
import styles from "./index.module.scss"

const NormalLayout = ({ children }) => {
  const { state } = useHomeContext();

  if (state.screen === ScreenType.START_SCREEN) {
    return <StartScreen />
  }
  return (
    <div>
      <NavHeader />
      <div className="bgBlur" />
      <div className={styles.container}>{children}</div>
    </div>
  );
};

export default NormalLayout;
