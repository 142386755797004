export const endpoint = "https://asia-south1-mankind-ai-pipeline.cloudfunctions.net";

export const FIREBASE_COLLECTION_VERSION = {
  V1: "v1",
};

export const ANIMATION_TYPE = {
  FADE_IN_UP: "FADE_IN_UP",
  FADE_IN: "FADE_IN",
};

export const VIDEO_STATUS = {
  CREATED: "CREATED",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
  CANCELLED: "CANCELLED",
};

export const PIPELINE_STATUS = {
  RUNNING: "RUNNING",
  COMPLETED: "COMPLETED",
  DELETED: "DELETED",
};