import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: JSON.parse(localStorage.getItem("user")) || null,
  reducers: {
    addUser: (state, action) => {
      let _user = action.payload;
      localStorage.setItem("user", _user);
      return JSON.parse(_user);
    },
    removeUser: (state, action) => {
      localStorage.clear();
      return null;
    },
  },
});

// this is for dispatch
export const { addUser, removeUser } = userSlice.actions;

// this is for configureStore
export default userSlice.reducer;
