import { Suspense } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Router from "./router";
import AppInstallPopUp from "./components/AppInstallPopUp";

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <ToastContainer />
      <Router />
      <AppInstallPopUp />
    </Suspense>
  );
}

export default App;

const Loader = () => {
  return <div></div>;
};
