import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function UnprotectedRoute(props) {
  // const user = useSelector((state) => state.user);
  // let location = useLocation();

  // if (user) {
  //   // Redirect them to the redirect page, but save the current location they were
  //   // trying to go to when they were redirected. This allows us to send them
  //   // along to that page after they login, which is a nicer user experience
  //   // than dropping them off on the home page.
  //   return (
  //     <Navigate to={props.redirectTo} state={{ from: location }} replace />
  //   );
  // }
  if (props.layout) {
    return <props.layout>{props.children}</props.layout>;
  }

  return props.children;
}
