import {
  onSnapshot,
  collection,
  doc,
  getDoc,
  query,
  orderBy,
  setDoc,
  arrayUnion,
  getDocs,
  where,
  serverTimestamp,
} from "firebase/firestore";
import {
  auth,
  firestore,
  database,
  analytics,
  setUserId,
  setUserProperties,
} from ".";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
} from "firebase/auth";
import { COLLECTION_NAME } from "../Constants/CollectionConstant";
import { FIREBASE_COLLECTION_VERSION } from "../Constants";
import { child, get, ref, push } from "@firebase/database";
import { log } from "@tensorflow/tfjs";

export const signUp = async (name, email, password) => {
  try {
    let userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    await updateProfile(user, {
      displayName: name,
      //   photoURL: "",
    });
    return user;
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log("error", errorMessage);
    return null;
  }
};

// window.signUp = signUp;

export const login = async (email, password) => {
  try {
    let userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;

    setUserId(analytics, user.uid);
    setUserProperties(analytics, {
      email: user.email,
      name: user.displayName,
    });

    return user;
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log("errorCode", errorCode);
    throw error;
  }
};

export const loginSignUp = async (name, email, password) => {
  try {
    let user = await login(email, password);

    return user;
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    if (errorCode == "auth/invalid-credential") {
      let _u = await signUp(name, email, password);
      if (_u) {
        return loginSignUp(name, email, password);
      }
    }
    throw error;
  }
};

export const logout = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    console.log("error", error);
  }
};

let finishedListenerRef = null;

export const getAssets = async () => {
  const dbRef = ref(database);
  const snapshot = await get(child(dbRef, `${COLLECTION_NAME.ASSETS}`));
  let obj = {};
  if (snapshot.exists()) {
    return snapshot.val();
  } else {
    return obj;
  }
};

export function readData(node) {
  return new Promise(async (resolve, reject) => {
    try {
      const dbRef = ref(database);
      const snapshot = await get(child(dbRef, node));
      if (snapshot.exists()) {
        resolve(snapshot.val());
      } else {
        console.log("No data available");
        resolve(null);
      }
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
}

export function writeDataInDB(node, data) {
  return new Promise(async (resolve, reject) => {
    try {
      const dataRef = ref(database, node);

      let res = await push(dataRef, { ...data, timestamp: serverTimestamp() });
      resolve(res.key);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
}

export const attachFinishedListener = (user, cb = () => {}) => {
  let _data = [];
  try {
    const usersRef = collection(firestore, COLLECTION_NAME.USER_GEN_ASSETS);

    let bodyOptionsDocRef = collection(
      usersRef,
      user.uid,
      COLLECTION_NAME.REQUESTS
    );

    const q = query(
      bodyOptionsDocRef,
      where("disabled", "==", false),
      orderBy("timestamp", "desc")
    );

    finishedListenerRef = onSnapshot(q, (snapshot) => {
      if (snapshot.empty) {
        cb(_data);
        window.dataFetched = true;
      } else {
        _data = [];
        // prevent to add multiple document
        snapshot.forEach((doc) => {
          _data.push(doc.data());
        });
        cb(_data);
        window.dataFetched = true;
      }
    });
  } catch (error) {
    cb(_data);
    console.error(error);
  }
};

export const removeFinishedListener = () => {
  if (finishedListenerRef) {
    finishedListenerRef();
  }
};

// export const updateUserImageData = async (user, data) => {
//   const usersRef = doc(
//     firestore,
//     COLLECTION_NAME.TEST_GEN_ASSETS,
//     user.uid,
//     COLLECTION_NAME.USER_IMAGE,
//     FIREBASE_COLLECTION_VERSION.V1
//   );

//   await setDoc(usersRef, data, { merge: true });
// };

export const updateUserData = async (
  uid = "tyj234sdfE0g78byzsPwpVc3B",
  userDetails
) => {
  const userDocRef = doc(firestore, COLLECTION_NAME.USER_GEN_ASSETS, uid);

  // Fetch the document to check if it exists
  const userDoc = await getDoc(userDocRef);
  const isNewDocument = !userDoc.exists();

  // Prepare the data to be set/updated
  const userDataWithTimestamp = {
    ...userDetails,
    uid,
    updatedAt: serverTimestamp(),
  };

  if (!isNewDocument) {
    const existingEvents = userDoc.data()?.events || [];
    userDataWithTimestamp.events = [
      ...new Set([...existingEvents, ...(userDetails.events || [])]),
    ];
  } else {
    userDataWithTimestamp.createdAt = serverTimestamp();
  }

  // Write the document, merging data
  await setDoc(userDocRef, userDataWithTimestamp, { merge: true });

  // Refetch the document to get the actual timestamp values // This will now contain the actual timestamps
};

// export const getUserImageData = async (user) => {
//   const usersRef = doc(
//     firestore,
//     COLLECTION_NAME.USER_GEN_ASSETS,
//     user.uid,
//     COLLECTION_NAME.USER_IMAGE,
//     FIREBASE_COLLECTION_VERSION.V1
//   );

//   let docSnap = await getDoc(usersRef);

//   if (docSnap.exists()) {
//     return docSnap.data();
//   } else {
//     return null;
//   }
// };

export const checkMSLCodeExists = async (employeeID, MSLCode) => {
  const usersRef = collection(
    firestore,
    COLLECTION_NAME.USER_GEN_ASSETS,
    employeeID,
    COLLECTION_NAME.REQUESTS
  );

  const q = query(usersRef, where("MSLCode", "==", MSLCode));

  let querySnapshot = await getDocs(q);

  if (!querySnapshot.empty) {
    // const data = querySnapshot.docs.map(doc => doc.data());
    return "Video is already processed with this MSL code";
  } else {
    return false;
  }
};
