import { toast } from "react-toastify";

export const showToast = (message, attributes = {}) => {
  let obj = {
    position: attributes.position || "top-right",
    autoClose: attributes.autoClose || 5000,
    hideProgressBar: attributes.hideProgressBar || true,
    closeOnClick: attributes.closeOnClick || true,
    pauseOnHover: attributes.pauseOnHover || false,
    draggable: attributes.draggable || true,
    progress: attributes.progress || undefined,
    className: "toast-container",
    type: attributes.type || "", //error,warn,success,info
  };

  toast(message, obj);
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isValidForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

export const handleInputChange = (event, dispatch, Action_Type) => {
  const target = event.target;
  const value = target.type === "checkbox" ? target.checked : target.value;
  const name = target.name;
  dispatch({
    type: Action_Type.handleInputChange,
    payload: { name, value },
  });
};

export const getInitials = (fullName) => {
  const allNames = fullName.trim().split(' ');
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, '');
  return initials;
}

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export function sortArrayByOrder(arr, property, order = 'asc') {
  return arr.sort((a, b) => {
    if (order === 'asc') {
      return a[property] > b[property] ? 1 : -1;
    } else if (order === 'desc') {
      return a[property] < b[property] ? 1 : -1;
    } else {
      throw new Error("Order must be 'asc' or 'desc'");
    }
  });
}
